body {
    background: #fafafa;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 40px;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster { /* example of image in css TODO: delete*/
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                -webkit-min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}


/* ==========================================================================
bs callouts
========================================================================== */
.bs-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
}
.bs-callout h4 {
    margin-top: 0;
    margin-bottom: 5px;
}
.bs-callout p:last-child {
    margin-bottom: 0;
}
.bs-callout code {
    border-radius: 3px;
}
.bs-callout+.bs-callout {
    margin-top: -5px;
}
.bs-callout-default {
    border-left-color: #777;
}
.bs-callout-default h4 {
    color: #777;
}
.bs-callout-primary {
    border-left-color: #428bca;
}
.bs-callout-primary h4 {
    color: #428bca;
}
.bs-callout-success {
    border-left-color: #5cb85c;
}
.bs-callout-success h4 {
    color: #5cb85c;
}
.bs-callout-danger {
    border-left-color: #d9534f;
}
.bs-callout-danger h4 {
    color: #d9534f;
}
.bs-callout-warning {
    border-left-color: #f0ad4e;
}
.bs-callout-warning h4 {
    color: #f0ad4e;
}
.bs-callout-info {
    border-left-color: #5bc0de;
}
.bs-callout-info h4 {
    color: #5bc0de;
}
/* jhipster-needle-css-add-main JHipster will add new css style */

/* anchor overrides */
/*a {
  color: #e50046;
}
a:hover,
a:focus {
  color: #B40036;
}*/
/* navbar overrides */
@media (min-width: 768px) {
  .navbar {
    border-radius: 0px;
    border-right: 0px;
    border-left: 0px
  }
}
.navbar-default {
  background-color: #fff;
}
.navbar-default .navbar-nav > li > a {
  color: #777;
}
.navbar-default .navbar-nav > li > a:hover {
  color: #e50046;
  background-color: transparent;
}
.navbar-default .navbar-nav > li > a:after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  margin: auto;
  color: transparent;
  background: #e50046;
  content: '';
  transition: width .2s linear;
}
.navbar-default .navbar-nav > li > a:hover:after,
.navbar-default .navbar-nav > .open > a:after,
.navbar-default .navbar-nav > .active > a:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:hover {
  color: #e50046;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #e50046;
  background-color: transparent;
}
/* dropdown menu overrides */
.dropdown-menu {
  background-color: #e7e7e7;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #e50046;
  background-color: #fff;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: #e50046;
}
/* dropdown menu overrides */
.dropdown-menu-light {
  background-color: #fff;
}
.dropdown-menu-light > li > a:hover,
.dropdown-menu-light > li > a:focus {
  color: #e50046;
  background-color: #e7e7e7;
}
/* Condensed Dark Navbar */
.navbar-inverse {
  background-color: #431c23;
}
.navbar-inverse .navbar-nav > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}
.navbar-inverse .dropdown-menu {
  background-color: #fff;
}
.navbar-inverse .dropdown-menu
.navbar-inverse .navbar-nav > li > a {
  color: #e7e7e7;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: transparent;
}
.navbar-inverse .dropdown-menu > li > a:hover,
.navbar-inverse .dropdown-menu > li > a:focus {
  color: #e50046;
  background-color: #e7e7e7;
}
.navbar-inverse .navbar-nav > li > a {
  color: #e7e7e7;
}
.navbar-inverse .navbar-nav > li > a .fas {
  color: #e50046;
}
.navbar-inverse .navbar-nav > li > a:hover .fas {
  color: #fff;
}
/* misc */
.content-well-min-height {
  min-height: 75vh;
}
.table-min-height {
  min-height: 45vh;
}
.home-img {
  height: 1081px;
  max-height: 80vh;
  background: url("../images/rbl.jpg") no-repeat center top;
  background-size: contain;
}
/* Buttons Override */
/*btn-primary*/
.btn-primary {
  background-color: #431c23;
  border-color: #3c191f;
}
.btn-primary:focus,
.btn-primary.focus {
  background-color: #35161c;
  border-color: #1f0d10;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-color: #35161c;
  border-color: #31141a;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #fff;
  background-color: #31141a;
  border-color: #1f0d10;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #431c23;
  border-color: #3c191f;
}
.btn-primary .badge {
  color: #431c23;
  background-color: #fff;
}
/*btn-info*/
.btn-info {
  color: #fff;
  background-color: #e50046;
  border-color: #ce003f;
}
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #b70038;
  border-color: #b20036;
}
.btn-info:hover {
  color: #fff;
  background-color: #b70038;
  border-color: #cc003e;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #b70038;
  border-color: #cc003e;
}
.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: #cc003e;
  border-color: #b20036;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus {
  background-color: #e50046;
  border-color: #ce003f;
}
.btn-info .badge {
  color: #e50046;
  background-color: #fff;
}
/* pagination */
.pagination > li > a,
.pagination > li > span {
  color: #431c23;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #35161c;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #431c23;
  border-color: #431c23;
}
/* nav-pills */
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: #431c23;
}
/* anchors */
a {
  color: #431c23;
}
a:hover,
a:focus {
  color: #35161c;
}
/* nav-pills */
.nav-pills > li > a:hover {
  text-decoration: underline;
}
/* panel-primary */
.panel-primary {
  border-color: #431c23;
}
.panel-primary > .panel-heading {
  padding: 5px 10px;
  background-color: #431c23;
  border-color: #431c23;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #431c23;
}
.panel-primary > .panel-heading .badge {
  color: #431c23;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #431c23;
}
/* checkbox color */
input[type=checkbox]{
  accent-color:  #e50046;
}

/* ==========================================================================
Misc. tweaks
========================================================================== */
.form-group > button + span {
    margin-left: 10px;
}
/* whitespace-independent spacing for form-inline child elements*/
.form-inline > * {
    margin-right: 10px;
    margin-bottom: 10px;
}
/* small font for entity info */
.entity-info {
    font-size: 11px
}
/* Margin hacks */
.mb0 {
    margin-bottom: 0 !important;
}
.mt0 {
    margin-top: 0 !important;
}
/* height tweaks */
body {
    min-height: 100vh;
}
/* accordion-toggle outline */
.accordion-toggle:focus {
    outline: none;
}
/* Clickable panel outline */
.panel-heading:focus {
    outline: none;
}
/* Fake (fontawesome) checkboxes */
a.checkbox-fa {
    text-decoration: none;
}
a.checkbox-fa .fa-check-square {
    color: #e50046
}
/* Accordion heading link style */
a.accordion-toggle {
    text-decoration: none;
}
/* list-group bottom margin in modal-dialog */
.modal-content .list-group {
    margin-bottom: 0;
}
/* ==========================================================================
Width tweaks
========================================================================== */
.container-flex {
    display: flex;
    flex-grow: 1;
    min-width: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.container-flex > .well {
    width: 100%;
}
.text-break-any {
    overflow-wrap: anywhere;
}
/* ==========================================================================
Color tweaks
========================================================================== */
/* Darker background for progress bars in wells */
.well .progress {
    background-color: #cccccc;
}
/* ==========================================================================
Flex
========================================================================== */
.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 15px;
}
.flex-row > * {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
}
.flex-row > .file-select-input {
    flex-basis: 50%;
}
.flex-row > .flex-right {
    margin-left: auto;
}
.flex-row .flex-align-self-end {
    align-self: flex-end;
}
/* flexbox for rows */
.row.display-flex {
    display: flex;
    flex-wrap: wrap;
}
/* row columns vertical alignment, so inputs are on the same baseline, while labels can grow upwards */
.row.display-flex > [class*='col-'] {
    flex-grow: 1;
    align-self: baseline;
}
/* ==========================================================================
Tables
========================================================================== */
/* table cell width to fit content */
.table th.fit-width,
.table td.fit-width {
    width: 1%;
    white-space: nowrap;
}
/* cell content vertical center alignment */
.vertical-center,
.table tbody > tr > td.vertical-center,
.table thead > tr > th.vertical-center,
.table tbody.vertical-center td,
.table tbody.vertical-center th {
    vertical-align: middle;
}
/* cell content vertical bottom alignment */
.vertical-bottom {
    vertical-align: bottom;
}
/* Centered th in theader */
thead.centered-theader > tr > th {
    text-align: center;
    vertical-align: middle;
}
/* Inside borders for table */
.inside-border th ,
.inside-border td {
    border-right: 1px solid #ddd;
}
.inside-border th:last-child ,
.inside-border td:last-child {
    border-right: none;
}
/* Upper border for table */
.top-border th ,
.top-border td {
    border-top: 1px solid #ddd !important;
}
/* greyed table row */
.table > thead > tr > td.greyed,
.table > tbody > tr > td.greyed,
.table > tfoot > tr > td.greyed,
.table > thead > tr > th.greyed,
.table > tbody > tr > th.greyed,
.table > tfoot > tr > th.greyed,
.table > thead > tr.greyed > td,
.table > tbody > tr.greyed > td,
.table > tfoot > tr.greyed > td,
.table > thead > tr.greyed > th,
.table > tbody > tr.greyed > th,
.table > tfoot > tr.greyed > th {
    background-color: #e8e8e8;
}
/* light background-color for table rows */
.table-light > tbody > tr {
    background-color: #f9f9f9;
}
/* Tables embedded in Panels */
.panel table td:first-child,
.panel table th:first-child {
    padding-left: 15px;
}
/* multiple inputs in table cell */
td > select:not(:first-child),
td > input:not(:first-child) {
    margin-top: 10px;
}
/* ==========================================================================
Table in another table cell
========================================================================== */
td#embedded-table {
    padding: 0;
}
.table-embedded {
    margin-bottom: 0;
}
/* ==========================================================================
Sidebar
========================================================================== */
.wrapper {
    display: flex;
    align-items: stretch;
    width: 100%;
    min-height: calc(100vh - 34px); /* viewheight - navbar height */
}
.sidebar-container {
    display: flex;
    flex: 0 0 auto;
    min-height: 100%;
}
.sidebar {
    width: 250px;
    background-color: #fff;
    color: #777;
    border-right: 1px solid #e7e7e7;
    transition: all 0.3s;
}
.sidebar-header {
    padding: 20px;
}
.sidebar .sidebar-nav > li {
    border-bottom: 1px solid #e7e7e7;
}
.sidebar .sidebar-nav > li > a {
    padding: 15px;
    line-height: 20px;
}
.sidebar .sidebar-nav > li > a:hover,
.sidebar .sidebar-nav > li > a:focus {
    background-color: transparent;
}
.sidebar .sidebar-nav > li > a:hover {
    color: #e50046;
}
.sidebar .sidebar-nav > li > a:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    margin: 0 auto;
    color: transparent;
    background: #e50046;
    content: '';
    transition: width .2s linear;
}
.sidebar .sidebar-nav > li > a:hover:after,
.sidebar .sidebar-nav > .open > a:after,
.sidebar .sidebar-nav > .active > a:after {
    width: 100%;
}
.sidebar .sidebar-nav > .active > a,
.sidebar .sidebar-nav > .active > a:hover,
.sidebar .sidebar-nav > .active > a:focus {
    background-color: transparent;
}
.sidebar .sidebar-nav > .active > a:hover {
    color: #e50046;
}
.sidebar-collapse {
    list-style: none;
    background-color: #e7e7e7;
    padding: 0;
}
.sidebar-collapse > li > a {
    padding: 15px;
    display: block;
    text-decoration: none;
    line-height: 20px;
}
.sidebar-collapse > li > a:hover,
.sidebar-collapse > li > a:focus {
    color: #e50046;
    background-color: #fff;
}
.sidebar-collapse > .active > a,
.sidebar-collapse > .active > a:hover,
.sidebar-collapse > .active > a:focus {
    background-color: #e50046;
    color: #fff;
}
@media (max-width: 992px) {
    .sidebar {
        min-width: 120px;
        max-width: 120px;
        text-align: center;
    }
    .logo .logo-img {
        max-width: 80px;
    }
}
.show-on-sm {
    display: none;
}
/* @media (max-width: 767px) {
    .show-on-sm {
        display: block;
    }
}
@media (max-width: 767px) {
    .hide-on-sm {
        display: none;
    }
} */
/* ==========================================================================
Navbar tweaks
========================================================================== */
.navbar-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
/* ==========================================================================
Dropdown tweaks
========================================================================== */
.dropdown-input {
    width: 100%;
    color: #555555;
    height: 34px;
    padding: 6px 6px 6px 20px;
    border: 0;
    border-bottom: 3px solid #e7e7e7;
}
.dropdown-input:focus {
    outline: 0;
    border-bottom-color: #e50046;
}
.dropdown-menu.scrollable,
.dropdown-menu-light.scrollable {
    max-height: 30vh;
    overflow-y: auto;
}
.dropdown-toggle.full-width {
    width: 100%;
    text-align: start;
}
.dropdown-toggle.full-width .dropdown-label {
    width: 99%;
    display: inline-block;
    margin-right: auto;
}
.dropdown-toggle.no-overflow-x .dropdown-label {
    vertical-align: bottom; /*иначе дорисовывает 5px ниже из-за display: inline-block*/
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.dropdown-menu.no-overflow-x {
    width: 100%;
}
.dropdown-menu.no-overflow-x > li > a {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
/* ==========================================================================
Dropdown nested ul
========================================================================== */
.dropdown-menu > li > .submenu-btn-group {
  width: 100%;
  display: flex;
}
.dropdown-menu > li > .submenu-btn-group:hover,
.dropdown-menu > li > .submenu-btn-group:focus {
  background-color: #f5f5f5;
}
.dropdown-menu > li > .submenu-btn-group:hover > .btn-link,
.dropdown-menu > li > .submenu-btn-group:focus > .btn-link {
  color: #262626;
}
.dropdown-menu-light > li > .submenu-btn-group:hover,
.dropdown-menu-light > li > .submenu-btn-group:focus {
    background-color: #e7e7e7;
}
.dropdown-menu-light > li > .submenu-btn-group:hover > .btn-link,
.dropdown-menu-light > li > .submenu-btn-group:focus > .btn-link {
    color: #e50046;
    outline: none;
}
.dropdown-menu > li > .submenu-btn-group > .btn-link {
    display: block;
    clear: both;
    color: #333333;
    font-weight: 400;
    line-height: 1.42857143;
    padding: 3px 20px;
    text-decoration: none;
    text-align: left;
    white-space: nowrap;
}
.dropdown-menu > li > .submenu-btn-group > .btn-link:focus {
    outline: none;
}
/* full-width */
.dropdown-menu > li > .submenu-btn-group > .btn-link.main {
  width: 100%;
}
.dropdown-submenu {
    list-style: none;
    padding-inline-start: 0;
}
.dropdown-submenu > li > a,
.dropdown-submenu > li > .btn-link {
    display: block;
    padding: 3px 30px;
    white-space: nowrap;
}
.dropdown-submenu > li > a:hover,
.dropdown-submenu > li > a:focus {
    text-decoration: none;
}